module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Itransition recruiting site","short_name":"itransition","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/icon-512x512.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ae11f5af3d56eb72ed5552624fc4a2db"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.itransition.com","noHash":true,"noTrailingSlash":true,"noQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T9T8K74","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"function () {\n        if (document.location) {\n          const {\n            location: { protocol, hostname, pathname, search },\n          } = document;\n          window.dataLayer = window.dataLayer || [];\n\n          return {\n            originalLocation: `${protocol}//${hostname}${pathname}${search}`,\n          };\n        }\n      }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
